<div class="layout-wrapper">
  <app-header></app-header>
  <div class="layout-main-container ">
    <div class="layout-main">
      <div id="home" class="site-wrapper">
        <ng-container *skeleton="isDataLoading; repeat: 2; className: 'mb-2'">
          <router-outlet></router-outlet>
        </ng-container>
      </div>

    </div>
  </div>

  <app-footer></app-footer>
</div>

<ngx-ui-loader bgsColor="#00ACC1" ></ngx-ui-loader>

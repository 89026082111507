<div id="mySidenav" class="sidenav" [style.width]="displayMobileMenu ? '100%' : '0'">

  <div class="top_navbar">
    <div class="sidenav-header flex flex-auto justify-content-between align-items-center">
      <div>
        <div class="flex flex-auto justify-content-start align-items-center">
          <a class="logo-link" (click)="close()">
            <img *ngIf="!currentUnit && inHoteisPage && appTarget" src="{{applicationService.photoBasePath}}/{{appTarget.splashIcon}}" height="56">
          </a>
          <a *ngIf="currentUnit" class="logo-link  flex-vcenter" routerLink="/site/{{currentUnit?.linkMenuNavigate}}"
            (click)="close()">
            <img *ngIf="currentUnit.logoIcon" src="{{applicationService.photoBasePath}}/{{currentUnit.logoIcon}}"
              height="56">

            <p *ngIf="currentUnit" class="hotel-title">{{currentUnit.name}}</p>
          </a>
        </div>
      </div>
      <div>
        <a href="javascript:void(0)" class="closebtn" (click)="close()">&times;</a>
      </div>
    </div>
  </div>

  <div class="menu-itens-container">
    <ng-container *ngFor="let menu of menus; let i = index;">
      <app-menu-item [menu]="menu" [unitLink]="unitLink" (onItemClicked)="close()"></app-menu-item>
    </ng-container>
  </div>

</div>

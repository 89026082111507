<div class="top_navbar  flex flex-auto justify-content-start align-items-center">

  <div class="navbar flex">

    <div class="layout-topbar-content responsive-center" >


      <div class=" flex flex-auto justify-content-between align-items-center" style="width: 100%;" >

        <div class="flex flex-auto justify-content-start align-items-center w-full">

          <a class="logo-link flex " [routerLink]="redirectHome" *ngIf="appTarget">
            <img src="{{applicationService.photoBasePath}}/{{appTarget.logoIcon}}" alt="Início" style="width: 100%;">
          </a>

          <div *ngIf="!isSmallMobile">
            <ng-container *ngTemplateOutlet="unitIconTemplate;"></ng-container>
          </div>

          <div *ngIf="loadingMenu" class="skeleton-loader sk-logo"></div>
          <div *ngIf="loadingMenu" class="skeleton-loader sk-menu w-full "></div>

          <div *ngIf="!isSmallMobile">
            <div *ngIf="!loadingMenu" class="flex flex-auto justify-content-center align-items-center">
              <app-horizontal-menu class="horizontal-menu flex" [menuData]="menuData"
                [unitLink]="currentUnit?.linkMenuNavigate"></app-horizontal-menu>
            </div>
          </div>
        </div>

        <div class="flex flex-auto justify-content-start align-items-center">
          <div *ngIf="isSmallMobile">
            <ng-container *ngTemplateOutlet="unitIconTemplate;"></ng-container>
          </div>
        </div>

        <div class="flex flex-auto justify-content-start align-items-center">
          <div >
            <div *ngIf="isSmallMobile" [style.width]="isSmallMobile ? '100px' : '*'">
              <fa-icon  [icon]="['fas', 'bars']" style="font-size: 22px; float: right; padding-right: 5px;" (click)="openMenuMobile()"></fa-icon>
            </div>
            <div *ngIf="!isSmallMobile" class="search-content flex align-items-center justify-content-center">
              <ng-container *ngFor="let menu of menuData | propertyFilter : 'displayMode' : 'Button'">
                <a class="btn btn-reserva" *ngIf="menu.applicationPage" [routerLink]="[menu.link | setLinkUnit : currentUnit?.linkMenuNavigate]">{{menu.itemName}}</a>
                <a class="btn btn-reserva" *ngIf="!menu.applicationPage"  href="{{menu.link}}"  target="{{ menu.openNewPage ? '_blank' : '_self' }}" rel="noopener">{{menu.itemName}}</a>
              </ng-container>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>

  <app-sidebar-menu [displayMobileMenu]="displayMobileMenu" [menus]="menuData" [currentUnit]="currentUnit" [appTarget]="appTarget"
    [inHoteisPage]="inHoteisPage" (onClosed)="displayMobileMenu = false"></app-sidebar-menu>

</div>

<ng-template #unitIconTemplate>
  <div class=" flex flex-auto justify-content-start align-items-center">

    <div *ngIf="!isSmallMobile" class="logo-separator"></div>

    <img *ngIf="!loadingMenu && !currentUnit && inHoteisPage && appTarget" src="{{applicationService.photoBasePath}}/{{appTarget.splashIcon}}" height="56">

    <a *ngIf="currentUnit" class="logo-link flex flex-auto justify-content-start align-items-center" [ngClass]="{'flex-column': isSmallMobile}"
      routerLink="/site/{{currentUnit?.linkMenuNavigate}}">

      <img *ngIf="currentUnit.logoIcon && menuModel?.showLogoIcon" src="{{applicationService.photoBasePath}}/{{currentUnit.logoIcon}}" [style.width]="isSmallMobile ? '42px' : '56px'">

      <p *ngIf="menuModel?.siteTitle && menuModel?.showSiteTitle" class="hotel-title">{{(menuModel?.siteTitle ? menuModel.siteTitle : currentUnit.name)}}</p>
    </a>
  </div>
</ng-template>

import { Injectable } from '@angular/core';
import { MenuChangeEvent } from '../../../shared/events/menuchangeevent';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MenuEventsService {

    private menuSource = new Subject<MenuChangeEvent>();
    public onItemClick = new Subject<any>();

    private resetSource = new Subject();

    menuSource$ = this.menuSource.asObservable();
    resetSource$ = this.resetSource.asObservable();

    onMenuStateChange(event: MenuChangeEvent) {
        this.menuSource.next(event);
    }

    onClick(event){
      this.onItemClick.next(event);
    }


    reset() {
        this.resetSource.next(true);
    }
}

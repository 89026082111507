<div class="dropdown">
  <a class="dropdown-btn rootlink" (click)="opened = !opened">
    {{menu.itemName}}
    <fa-icon [icon]="['fas', 'angle-down']" [@rotatebtn]="submenuAnimation"></fa-icon>
  </a>

  <div class="dropdown-container" [style.height]="opened ? 'auto' : '0px'" [@children]="submenuAnimation">

    <ng-container *ngFor="let menuItem01 of menu.menuItens">

      <ng-container *ngIf="menuItem01.type == 'Category'">
        <ng-container *ngTemplateOutlet="templateCategory; context: {data: { item: menuItem01}}">
        </ng-container>
      </ng-container>

      <ng-container *ngIf="menuItem01.type != 'Category'">
        <ng-container *ngTemplateOutlet="templateOfItem; context: {data: { item: menuItem01}}">
        </ng-container>
      </ng-container>

    </ng-container>

  </div>

  <div class="separator"></div>

</div>

<ng-template #templateCategory let-item="data.item">
  <app-dropdown-button [menu]="item" [unitLink]="unitLink" (onItemClicked)="onItemClicked.emit($event)"></app-dropdown-button>
</ng-template>

<ng-template #templateOfItem let-item="data.item">
  <a href="{{item.link | setLinkUnit : unitLink}}" target="{{ item.openNewPage ? '_blank' : '_self' }}" rel="noopener"
    (click)="itemClicked()">{{item.itemName}}
  </a>
</ng-template>

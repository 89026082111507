import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Gallery, GalleryRef } from 'ng-gallery';
import { Subject, takeUntil } from 'rxjs';

import { ApplicationService } from 'src/app/shared/services/application.service';
import { BusinessUnitEntity } from 'src/app/shared/models/unit';
import { CarouselService } from 'src/app/shared/services/carousels.service';
import { CarouselViewDTO } from 'src/app/shared/models/carouselViewDTO';
import { MenuItemModel } from 'src/app/shared/models/menu';
import { SubMenuService } from 'src/app/shared/services/sub-menu.service';

@Component({
  selector: 'app-submenu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss']
})
export class SubMenuComponent implements OnInit, OnDestroy {

  isSmallMobile: boolean = false;
  private _unsubscribeAll = new Subject();
  private _loadingMenu: boolean = false;


  currentUnit: BusinessUnitEntity;

  menus: any[];
  menuData: MenuItemModel[] = [];

  constructor(private _menuService: SubMenuService, public applicationService: ApplicationService) {

  }

  ngOnInit(): void {

    this.applicationService.onSmallMobile$.pipe(takeUntil(this._unsubscribeAll)).subscribe(isSmall => {
      this.isSmallMobile = isSmall;
    });

    this._menuService.service.onDataLoadEvent$.pipe(takeUntil(this._unsubscribeAll)).subscribe(resp => {
      if (resp) {
        this.menuData = resp;
      }
    });

    this._menuService.onLoading$.pipe(takeUntil(this._unsubscribeAll)).subscribe(resp => {
      this._loadingMenu = resp;
    });


    this.applicationService.unitService.onItemLoadEvent$.pipe(takeUntil(this._unsubscribeAll)).subscribe(resp => {

      this._menuService.loadingEvent.next(false);
      if(resp &&  resp?.subMenuSiteId && resp.id != this.currentUnit?.id) {
        this._menuService.loadingEvent.next(true);
        this._menuService.service.get(`getByMenuId/${resp.subMenuSiteId}`).then(resp => {
          this._menuService.loadingEvent.next(false);
        })
      }

      this.currentUnit = resp;
    });

  }


  ngOnDestroy(): void {
    this._menuService.service.clearList();
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    this._unsubscribeAll.unsubscribe();
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { MenuItemModel } from 'src/app/shared/models/menu';

@Component({
  selector: 'app-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss'],
  animations: [
    trigger('children', [
      state('collapsed', style({
        height: '0'
      })),
      state('expanded', style({
        height: '*'
      })),
      transition('collapsed <=> expanded', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ]),

    trigger('rotatebtn', [
      state('collapsed', style({
        transform: 'rotate(0)'
      })),
      state('expanded', style({
        transform: 'rotate(-180deg)'
      })),
      transition('collapsed <=> expanded', animate('700ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])

  ]
})
export class DropdownButtonComponent implements OnInit {

  @Input()
  menu: MenuItemModel;

  @Input()
  unitLink: string;

  @Input()
  opened: boolean;

  @Output()
  onItemClicked: EventEmitter<any> = new EventEmitter();


  constructor() { }

  ngOnInit(): void {
  }

  get submenuAnimation() {
    return (this.opened ? 'expanded' : 'collapsed');
  }

  itemClicked(){
    this.onItemClicked.emit();
  }

}

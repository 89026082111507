import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MenuItemModel, MenuModel } from 'src/app/shared/models/menu';
import { takeUntil } from 'rxjs';

import { ApplicationService } from 'src/app/shared/services/application.service';
import { MenuEventsService } from './app.menu.service';
import { MenuService } from 'src/app/shared/services/menu.service';
import { ComponentBase } from 'src/app/shared/component-base';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
})
export class MenuComponent extends ComponentBase implements OnInit, OnDestroy {

  displayMobileMenu: boolean = false;
  inHoteisPage: boolean = false;
  loadingMenu: boolean = true;
  redirectHome = `./${environment.redirectHome.replace('site/', '')}`;

  menuModel: MenuModel;
  menus: any[];
  menuData: MenuItemModel[] = [];

  constructor(private _menuService: MenuService,
    public menuEventsService: MenuEventsService,
    public applicationService: ApplicationService,
    public router: Router,
    private activatedRoute: ActivatedRoute,) {
    super();


  }


  override ngOnInit() {


    this._menuService.service.onDataLoadEvent$.pipe(takeUntil(this._unsubscribeAll)).subscribe(resp => {
      if (resp && (JSON.stringify(resp) != JSON.stringify(this.menuData || {}))) {
        this.menuData = resp;
        this.formatMenuModel();
      }
    });

    this._menuService.onLoading$.pipe(takeUntil(this._unsubscribeAll)).subscribe(resp => {
      this.loadingMenu = resp;
    });

    this.applicationService.appService.onItemLoadEvent$.pipe(takeUntil(this._unsubscribeAll)).subscribe(app => {



    });


    this.applicationService.unitService.onItemLoadEvent$.pipe(takeUntil(this._unsubscribeAll)).subscribe(resp => {

      if (resp && resp.id != this.currentUnit?.id) {
        this.currentUnit = resp;

        if (resp?.menuSiteId != this.menuModel?.id) {
          this._menuService.loadingEvent.next(true);
          this._menuService.service.get(`getByMenuId/${resp.menuSiteId}`).then(resp => {

            if (resp && resp.length > 0) {
              this.menuModel = resp[0].menu;
            } else {
              this.menuModel = null
            }

            this._menuService.loadingEvent.next(false);
          })
        }

      }

      if(!resp) {
        this.currentUnit = null;
        this.menuModel = null
      }




    });

    this.menuEventsService.onItemClick.pipe(takeUntil(this._unsubscribeAll)).subscribe(item => {
      if (item.applicationPage) {
        this.displayMobileMenu = false;
      }
    });

    this.router.events.pipe(takeUntil(this._unsubscribeAll)).subscribe((event: NavigationEnd) => {
      if (event instanceof NavigationEnd) {
        this.inHoteisPage = event.url.indexOf('/site/home') > -1 ? true : false;
      }
    });



    super.ngOnInit();

  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth > 991) {
      this.displayMobileMenu = false;
    }
  }


  formatMenuModel() {
    this.menus = this.menuData.map(it01 => {
      return {
        ...this.seeMenuItem(it01),
        items: !it01.menuItens?.length ? null : it01.menuItens.map(it02 => {
          return {
            ...this.seeMenuItem(it02),
            items: !it02.menuItens?.length ? null : it02.menuItens.map(it03 => {
              return {
                ...this.seeMenuItem(it03),
                items: !it03.menuItens?.length ? null : it03.menuItens.map(it04 => {
                  return {
                    ...this.seeMenuItem(it04)
                  }
                })
              }
            })
          }
        })
      }
    });
  }

  seeMenuItem(item: MenuItemModel) {

    item.link = item.link.replace('<unit>', this.currentUnit?.linkMenuNavigate);

    var resp = {
      label: item.label,
      url: item.link,
      routerLink: item.applicationPage ? [item.link] : null,
      icon: item.icon,
      disabled: false,
      type: item.type,
      openNewPage: item.openNewPage,
      applicationPage: item.applicationPage,
      target: item.openNewPage ? '_blank' : '_self'
    };

    if (item.menuItens?.length > 0 || item.type == 'Category') {
      resp.url = '';
    }

    return resp;
  }

  openMenuMobile() {
    this.formatMenuModel();
    this.displayMobileMenu = true;
  }


}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { MenuItemModel } from 'src/app/shared/models/menu';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent implements OnInit {

  @Input()
  menu: MenuItemModel;

  @Input()
  unitLink: string;

  @Output()
  onItemClicked: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  itemClicked(){
    this.onItemClicked.emit();
  }

}

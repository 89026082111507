import { Component, OnDestroy, OnInit } from '@angular/core';
import { faEnvelope, faMessage, faPhone } from '@fortawesome/free-solid-svg-icons';

import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { ComponentBase } from 'src/app/shared/component-base';
import { ApplicationService } from 'src/app/shared/services/application.service';
import { Router } from '@angular/router';
import { SetLinkUnitPipe } from 'src/app/shared/pipes/set-link-unit.pipe';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent extends ComponentBase implements OnInit, OnDestroy{

  faPhone = faPhone;
  faWhatsapp = faWhatsapp;
  faEnvelope = faEnvelope;
  faMessage = faMessage;

  appTargetId = environment.appTargetId;
  currentYear: number = (new Date().getFullYear());


  constructor(public applicationService: ApplicationService, private router: Router) {
    super();
  }

  override ngOnInit() { super.ngOnInit(); }

  override ngOnDestroy() { super.ngOnDestroy(); }

  openContactPage() {

    if (this.currentUnit?.uRLContact) {

      if(this.currentUnit?.uRLContact.indexOf('http') > -1) {
        window.open(this.currentUnit?.uRLContact, '_blank', 'noopener');
      } else {
        const url = new SetLinkUnitPipe().transform(this.currentUnit?.uRLContact, this.currentUnit.linkMenuNavigate);
        this.router.navigate([url]);
      }

    } else {
      this.router.navigate([`/site/${this.currentUnit?.linkMenuNavigate}/contato` ]);
    }


  }

}


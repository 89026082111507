<div class="horizontalmenu-content item-content flex flex-row justify-content-start align-items-center">

<ng-container *ngFor="let menu of menuData | propertyFilter : 'displayMode' : 'NormalLink'">

    <ng-container *ngIf="menu.menuItens?.length > 0; else templateSingleMenu">

      <div class="dropdown align-items-center justify-content-center align-content-center">

        <a class="dropbtn flex align-items-center">
          {{menu.itemName}}
        </a>

        <div class="dropdown-content">
          <div class="header">
            <!-- <h2>{{menu.itemName}}</h2> -->
          </div>

          <div class="row responsive-center">
            <ng-container *ngFor="let menuItem01 of menu.menuItens">

              <ng-container *ngIf="menuItem01.type == 'Category'; else templateOfItem">
                <div class="column">
                  <h3><b>{{menuItem01.itemName}}</b></h3>
                  
                  <ng-container *ngFor="let menuItem02 of menuItem01.menuItens">
                    <a *ngIf="menuItem02.applicationPage" class="sub-item" [routerLink]="[menuItem02.link | setLinkUnit : unitLink]" >{{menuItem02.itemName}}</a>

                    <a *ngIf="!menuItem02.applicationPage" class="sub-item" href="{{menuItem02.link | setLinkUnit : unitLink}}"  target="{{ menuItem02.openNewPage ? '_blank' : '_self' }}" rel="noopener">{{menuItem02.itemName}}</a>
  
                  </ng-container>

                  
                </div>
              </ng-container>

              <ng-template #templateOfItem>
                <a href="{{menuItem01.link | setLinkUnit : unitLink}}"  target="{{ menuItem01.openNewPage ? '_blank' : '_self' }}" rel="noopener">{{menuItem01.itemName}}</a>
              </ng-template>

            </ng-container>

          </div>


        </div>


      </div>

    </ng-container>

    <ng-template #templateSingleMenu>


      <a *ngIf="menu.applicationPage" [routerLink]="[menu.link | setLinkUnit : unitLink]">{{menu.itemName}}</a>

      <a *ngIf="!menu.applicationPage"  href="{{menu.link}}"  target="{{ menu.openNewPage ? '_blank' : '_self' }}" rel="noopener">{{menu.itemName}}</a>
    </ng-template>



</ng-container>
</div>

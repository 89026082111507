import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AppTargetEntity } from 'src/app/shared/models/appTarget';
import { ApplicationService } from 'src/app/shared/services/application.service';
import { BusinessUnitEntity } from 'src/app/shared/models/unit';
import { MenuItemModel } from 'src/app/shared/models/menu';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent implements OnInit {

  @Input()
  displayMobileMenu: boolean;

  @Input()
  unitLink: string;

  @Input()
  menus: MenuItemModel[] = [];

  @Input()
  appTarget: AppTargetEntity;

  @Input()
  currentUnit: BusinessUnitEntity;

  @Input()
  inHoteisPage: boolean = false;

  @Output()
  onClosed: EventEmitter<any> = new EventEmitter();

  houseId = environment.houseId;


  constructor(public applicationService: ApplicationService) { }

  ngOnInit(): void {
  }

  close() {
    this.onClosed.emit();
  }

}

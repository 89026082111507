<div class="submenu-content">
  <div *ngIf="currentUnit" class=" grid">
    <div class="col-12 md:col-6" *ngFor="let menu of menuData">
      <div class="submenu">
        <span class="title">{{menu.label}}</span>
        <span class="item" *ngFor="let item of menu.menuItens">

          <a *ngIf="item.applicationPage" [routerLink]="[item.link | setLinkUnit : currentUnit.linkMenuNavigate]">{{item.itemName}}</a>

          <a *ngIf="!item.applicationPage" href="{{item.link | setLinkUnit : currentUnit.linkMenuNavigate}}"
            target="{{ menu.openNewPage ? '_blank' : '_self' }}"  rel="noopener" >{{item.itemName}}</a>

        </span>
      </div>
    </div>
  </div>
</div>

<div class="layout-footer " *ngIf="currentUnit">
  <div class="responsive-center">


    <div class="footer-cont">

      <div class="flex {{isSmallMobile ? 'flex-column justify-content-center' : 'flex-auto justify-content-between'}}  align-items-top">

        <div class="logo-footer-content " >

          <div class="logo-footer flex flex-auto justify-content-center align-items-center">

            <ng-container *ngIf="!currentUnit?.footerShowOnlyUnitLogo; else logoUnit">
              <img *ngIf="appTarget" src="{{applicationService.photoBasePath}}/{{appTarget.logoIconWhite}}" alt="Logo" height="80" class="mr-3 ml-3">
            </ng-container>

            <ng-template #logoUnit>
              <img src="{{applicationService.photoBasePath}}/{{currentUnit.logoIconWhite}}" alt="Logo" height="80" class="mr-3 ml-3">
            </ng-template>

          </div>

          <div class="white-separator "></div>

          <div class="flex flex-auto justify-content-around align-items-center">
            <div class="social-icon" *ngIf="currentUnit?.linkedInUrl">
              <a href="{{currentUnit?.linkedInUrl}}" target="_blank" rel="noopener">
                <img src="assets/images/linkedIN.svg" alt="Botão LinkedIn">
              </a>
            </div>
            <div class="social-icon" *ngIf="currentUnit?.instagramUrl">
              <a href="{{currentUnit?.instagramUrl}}" target="_blank" rel="noopener">
                <img src="assets/images/instagram.svg" alt="Botão Instagram">
              </a>
            </div>
            <div class="social-icon" *ngIf="currentUnit?.twitterUrl">
              <a href="{{currentUnit?.twitterUrl}}" target="_blank" rel="noopener">
                <img src="assets/images/twitter.svg" alt="Botão Twitter">
              </a>
            </div>
            <div class="social-icon" *ngIf="currentUnit?.facebookUrl">
              <a href="{{currentUnit?.facebookUrl}}" target="_blank" rel="noopener">
                <img src="assets/images/facebook.svg" alt="Botão Facebook">
              </a>
            </div>
            <div class="social-icon" *ngIf="currentUnit?.youtubeUrl">
              <a href="{{currentUnit?.youtubeUrl}}" target="_blank" rel="noopener">
                <img src="assets/images/youtube.svg" alt="Botão Youtube">
              </a>
            </div>
            <div class="social-icon" *ngIf="currentUnit?.flickerUrl">
              <a href="{{currentUnit?.flickerUrl}}" target="_blank" rel="noopener">
                <img src="assets/images/flicker.svg" alt="Botão Flicker">
              </a>
            </div>
          </div>

        </div>


        <div></div>

        <div class="address-content " >

          <ng-container *ngIf="currentUnit">
            <a *ngIf="currentUnit.logoIconWhite && !currentUnit.footerShowOnlyUnitLogo" class="logo-link flex-vcenter" routerLink="/site/{{currentUnit?.linkMenuNavigate}}">
              <img  src="{{_appService.photoBasePath}}/{{currentUnit.logoIconWhite}}" height="70">

              <p class="hotel-title">{{currentUnit.name}}</p>
            </a>

            <div class="address" *ngIf="currentUnit?.address">
              <span>{{currentUnit?.address.address}}, {{currentUnit?.address.number}} - {{currentUnit?.address.neighborhood}}, {{currentUnit?.address.city}} - {{currentUnit?.address.state}}</span>
              <br>
              <span>CEP: {{currentUnit?.address.zipCode}}</span>
            </div>

            <div class="contact" *ngIf="currentUnit?.address">

              <div class="flex align-items-center  pb-1">
                <i class="contact-icon pi pi-envelope"></i>
                <span class="pl-3">
                  <a href="mailto:{{currentUnit?.address.mailBox}}">{{currentUnit?.address.mailBox}}</a>
                </span>
              </div>

              <div class="flex align-items-center pb-1" *ngFor="let contact of currentUnit.contacts">
                <i *ngIf="contact.contactType?.type == 'PHONE'" class="contact-icon pi pi-phone"></i>
                <i *ngIf="contact.contactType?.type == 'Celular'" class="contact-icon pi pi-phone"></i>
                <i *ngIf="contact.contactType?.type == 'WHATSAPP'" class="contact-icon pi pi-whatsapp"></i>

                <span class="pl-3 " *ngIf="contact.contactType?.type != 'WHATSAPP'" >
                  <a href="tel:{{contact.areaCode + contact.contact}}">{{ ('(' + contact.areaCode + ') ' + contact.contact) }}</a>
                </span>

                <span class="pl-3 " *ngIf="contact.contactType?.type == 'WHATSAPP'" >
                  <a target="_blank" rel="noopener" href="https://wa.me/55{{contact.areaCode + contact.contact}}">{{ ('(' + contact.areaCode + ') ' + contact.contact) }}</a>
                </span>
              </div>

              <div class="flex align-items-center pb-1" *ngIf="appTargetId == '134317ac-cdfd-4e29-8c93-765d6f473d20'">
                <fa-icon [icon]="faMessage"></fa-icon>
                <a class="pl-3" (click)="openContactPage()">{{(this.currentUnit?.uRLContact ? 'Fale conosco' : 'Fale conosco Sesc/RS')}}</a>
              </div>

            </div>

          </ng-container>

        </div>

      </div>


      <div class="grid" style="padding-top: 35px;">

        <div class="col-12 md:col-7">
          <app-submenu></app-submenu>
        </div>


      </div>


    </div>



  </div>

  <div class="text-bottom"><p>© {{currentYear}} Fecomércio-RS. Todos os direitos reservados.</p></div>
</div>


<ng-container *ngIf="menu.menuItens?.length > 0; else templateSingleMenu">

  <app-dropdown-button [menu]="menu" [unitLink]="unitLink" (onItemClicked)="onItemClicked.emit($event)"></app-dropdown-button>

</ng-container>

<ng-template #templateSingleMenu>

  <a class="rootlink" *ngIf="menu.applicationPage" [routerLink]="[menu.link | setLinkUnit : unitLink]" (click)="onItemClicked.emit($event)">{{menu.itemName}}</a>

  <a class="rootlink" *ngIf="!menu.applicationPage" href="{{menu.link}}" (click)="onItemClicked.emit($event)"
    target="{{ menu.openNewPage ? '_blank' : '_self' }}" rel="noopener">{{menu.itemName}}</a>

  <div class="separator"></div>

</ng-template>

import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { faAngleDown, faBars, faCaretDown, faCaretUp, faFilter, faHamburger, faMagnifyingGlass, faPhone, faTrash, faUser, faStar as fasStar } from '@fortawesome/free-solid-svg-icons';
import { faCaretSquareDown, faStar as farStar, faTrashAlt as farTrashAlt } from '@fortawesome/free-regular-svg-icons';

import AOS from "aos";
import { ApplicationService } from '../shared/services/application.service';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { LocalstorageService } from '../local.storage.service';
import { MenuComponent } from './header/menu/menu.component';
import { SeoService } from '../shared/services/seo.service';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-layout',
  templateUrl: './app.layout.component.html'
})
export class AppLayoutComponent implements OnDestroy, OnInit {

  overlayMenuOpenSubscription: Subscription;
  menuOutsideClickListener: any;
  inHoteisPage: boolean = false;
  isDataLoading: boolean = false;
  routeDataSubscriber: any;
  public static isNavigated: boolean = false;

  @ViewChild(MenuComponent) menuComponent!: MenuComponent;

  constructor(
    private library: FaIconLibrary,
    private service: ApplicationService,
    private seoService: SeoService,
    private router: Router,
    private localStorageService: LocalstorageService,
    @Inject(PLATFORM_ID) private platformId: any) {

    this.service.loadingEvent.subscribe(resp => {
      this.isDataLoading = resp;
    });

    this.configureIcons();

    this.router.events.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (event: NavigationStart) => {
          if (event instanceof NavigationStart) {

            AppLayoutComponent.isNavigated = true;
            this.seoService.titleService.setTitle(environment.siteTitle);

            if (this.routeDataSubscriber) {
              this.routeDataSubscriber.unsubscribe();
            }

          }
        });

    this.router.events.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (event: NavigationEnd) => {
          if (event instanceof NavigationEnd) {
           
            if (isPlatformBrowser(this.platformId)) {
              if (!AppLayoutComponent.isNavigated) {
                console.warn('A navegação foi um F5 (reload)');

                setTimeout(() => {
                  window.scrollTo(0, 2);
                }, 400);

              
                setTimeout(() => {
                  window.scrollTo(0, 0);
                }, 600);
              } else {
                console.warn('A navegação veio de um clique na aplicação');
              }
            }

            AppLayoutComponent.isNavigated = false;

            setTimeout(() => {
              this.configureAnalytics(event);
            }, 300);
          }
        });

  }


  configureAnalytics(event: NavigationEnd) {

    if (!this.seoService.activatedRoute) {
      this.seoService.setUpAnalytics(event.urlAfterRedirects);
      return;
    }

    this.routeDataSubscriber = this.seoService.activatedRoute.data.subscribe(data => {

      if (data)
        this.seoService.disableGaAutoLog = data['disableGaAutoLog'];
      else
        this.seoService.disableGaAutoLog = false;

      if (!this.seoService.disableGaAutoLog) {
        setTimeout(() => {
          console.warn('auto setUpAnalytics: ', event.urlAfterRedirects);
          this.seoService.setUpAnalytics(event.urlAfterRedirects);
        }, 200);
      }

    });

  }


  ngOnInit(): void {

    if (isPlatformBrowser(this.platformId)) {
      AOS.init();
    }

    this.service.getApp('', this.localStorageService).then(app => {
      // if (event.url.indexOf('/home') > -1) { }
    });

  }

  configureIcons() {

    this.library.addIcons(
      faHamburger,
      faBars,
      faCaretDown,
      faCaretUp,
      faCaretSquareDown,
      faAngleDown,
      fasStar, farStar,
      faMagnifyingGlass,
      faPhone,
      faUser,
      faFilter,
      faTrash, farTrashAlt
    );
  }


  ngOnDestroy() {
    this.destroySubscribers();

    if (this.overlayMenuOpenSubscription) {
      this.overlayMenuOpenSubscription.unsubscribe();
    }

    if (this.menuOutsideClickListener) {
      this.menuOutsideClickListener();
    }
  }

  public _unsubscribeAll = new Subject();
  public destroySubscribers(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    this._unsubscribeAll.unsubscribe();
  }


}

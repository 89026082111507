import { BehaviorSubject, Observable } from "rxjs";

import { ApiService } from "src/app/shared/services/api";
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MenuItemModel } from "../models/menu";
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class SubMenuService {

  public loadingEvent = new BehaviorSubject<boolean>(false);
  get onLoading$(): Observable<boolean> {
    return this.loadingEvent.asObservable();
  }

  public service: ApiService<MenuItemModel, MenuItemModel[]>;


  constructor(private http: HttpClient) {
    this.service = new ApiService(http, `${environment.urls.bffUrl}/menuItem`);
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { MenuItemModel, MenuModel } from 'src/app/shared/models/menu';

@Component({
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html'
})
export class HorizontalMenuComponent implements OnInit {

  @Input()
  menuData: MenuItemModel[] = [];

  @Input()
  unitLink: string;

  constructor() { }

  ngOnInit(): void {
  }

}
